$main-bg-color: #858689;
$rx-background-color: #cacaca;
$rx-card-background-color: #fff;
$rx-card-title-background-color: #f3f3f3;
$rx-select-blue: #bfeafb;
$rx-icons-blue: #8edaf8;
$rx-back-button-blue: #40c1f3;
$mat-field-color: rgba(0, 0, 0, 0.42);
$mat-select-arrow-disabled-color: #c2c2c4;

$blue-1: #0c68aa;
$blue-2: #448ebf;
$blue-3: #408dc1;
$blue-print: #18a5f2;
$blue-light-1: #b7e7f9;
$blue-light-2: #9fdff7;
$blue-light-3: #8edaf82b;

$gray-1: #adadad;
$gray-2: #8c8c8c;
$gray-light-1: #e0e0e0;
$gray-light-2: #d4d4d4;
$gray-light-3: #ccc;
$gray-light-1: #ccc;
$gray-light-2: #e0e0e0;
$gray-dark-1: #333;
$white-1: #e7e7e9;
$white-2: #fdfdfd;

$red-1: #dc3545;
$red-2: #ff3355;
$red-light: #ffccd4;

$black: black;

$mat-guy: gray;
$notes-blue: #419bf9;
$tooth-number: #8fd7f7;
$text-muted-color: #777;
$treatment-table-background-color: #f4f4f5;
$unit-type-menu-background-color: #f4f4f5;
$lab-background-color: #f4f4f5;
$unit-type-menu-border-color: #ccc;
$unit-type-arrow-shadow-color: lightgray;
$tooth-editor-teeth-section-background: #e9eaea;
$tooth-editor-background-color: #fff;
$tooth-editor-border-color: #ccc;
$blue-button-background-color: #5bc0de;
$bridge-warning-background-color: #f2dede;
$error-color: red;
$bridge-warning-text-color: #a94442;
$hover-background: #ddd;
$lab-border-color: #777;
$print-text-color: #3e3d40;
$generic-text-color: #3e3d40;
$readonly-text-color: rgba(62, 61, 64, 0.5);
$disabled-text-color: rgba(62, 61, 64, 0.4);
$border-color: #c8c9cb;
$popup-background-color: #f4f4f4;
$rx-banner-info-blue: #9ed9f4;
$rx-banner-info-blue-light: #f5fbfe;
$rx-banner-notification-yellow: #ffcc02;
$rx-banner-notification-yellow-light: #fffae5;
$print-background: #fff;
$rx-card-border: 1px;
$rx-expansion-panel-background: #E0E0E0;
$rx-awareness-aqua: #2BD3CA;
$rx-awareness-deep-purple: #503F81;
$rx-awareness-linear-gradient-purple: linear-gradient(135deg, #523178 0%, #3F2A56 49.59%, #8C4799 100%);

/*********layout-sizes**********/

$layout-mw: 1280px;
$layout-mobile: 63em; // Breakpoint for narrow/vertical style

/*******************************/

/************fonts**************/
$big-font-size: 25px;
$normal-font-size: 18px;
$medium-font-size: 16px;
$small-font-size: 14px;
$mobile-small-font-size: 10px;
$doctor-font-size: $normal-font-size;
$text-field-title-size: $small-font-size;
$alert-font-size: $normal-font-size;
$footer-font-size: $normal-font-size;
$rx-card-body-font-size: $normal-font-size;
$rx-tooth-font-size: $normal-font-size;
$rx-card-font-size: 20px;
$dialog-font-size: 18px;
$treatment-table-th: $small-font-size;

/*******************************/

$radio-button-outer-diameter: 23px;
$radio-button-outer-border: 0.5px;

/************font-family*********/
$main-font-family: 'Avenir', Roboto, 'Helvetica Neue', sans-serif;
$font-family-avenir: 'Avenir';
$font-family-avnir-gothic-sans-serif : 'Avenir', 'Nanum Gothic', sans-serif;
$font-family-avenir-roman: Avenir-Roman;
$rx-awareness-font-family: NeueHaasGroteskDisplay, Roboto, 'Helvetica Neue', sans-serif;
/*******************************/

/***********Opacity*************/
$opacity-disabled: 0.4;
$opacity-readonly: 0.5;
/*******************************/

$rx-separator-color: #cacaca;
$rx-dropdown-option-height: 3em; // mat-option style

// 8.5 because on MAC scroll invisible until navigate to the scroll area, we need to show part of the 9th item
$options-panel-8: 8.5 * $rx-dropdown-option-height;
$options-panel-5: 5.5 * $rx-dropdown-option-height;

