@import "variables";

.rx-separator {
  border: solid 0 $rx-separator-color;
  &.vertical {
    border-right-width: 1px;
    border-bottom-width: 0;
  }
  &.horizontal {
    border-bottom-width: 1px;
    border-right-width: 0;
  }
  &.transparent {
    border-color: transparent;
  }
  &.visible {
    border-color: $rx-separator-color;
  }
}