@import "variables";

@mixin denture-details-info($isPrintContext: false) {
  .denture-details-info {
    .denture-details-info__title {
      letter-spacing: 0.4px;
      text-align: left;
      color: $print-text-color;

      @if $isPrintContext {
        border: 1px solid #cacaca;
        border-bottom: none;
        background-color: #fff;
        padding: 5px 20px;
        font: bold 16px/2 $main-font-family;
      } @else {
        border-bottom: 1px solid #ccc;
        background-color: $rx-card-title-background-color;
        padding: 10px 20px 10px 20px;
        font: 500 20px/2 $main-font-family;
      }
    }

    .denture-details-info__selector {
      width: 14px;
      height: 14px;
      margin-left: 14px;
    }

    .denture-details-info__mould-image {
      width: 44px;
      height: 44px;
      margin-left: 14px;
    }

    .denture-details-info__selectors-container {
      margin-top: 7px;
    }
  }
}
